import { galleryImages, galleryImages2 } from "./cultivators/images";

export const cultivatorsEventPanels = [
  {
    id: 800,
    service_id: 16,
    title: "Cultivators Forum",
    title_slug: "cultivators-forum",
    post_summary: `The Cultivators Forum is a dynamic series of community-driven events aimed at empowering individuals worldwide.`,
    md_text: `The Cultivators Forum is a dynamic series of community-driven events aimed at empowering individuals worldwide. It aims to inspire and equip attendees to create positive impacts for the future. Key to each event is expert-led panel discussions covering a wide range of topics crucial to today's entrepreneurial world. These panels unite experienced mentors, industry leaders, innovative entrepreneurs, and savvy investors, encouraging vibrant exchanges of insights, perspectives, and actionable strategies. Participants can anticipate an immersive experience that transcends traditional networking, offering in-depth explorations of key global business challenges and opportunities. By fostering a collaborative environment where diverse voices converge, the Cultivators Forum cultivates a supportive community poised to drive significant innovation and sustainable global growth.`,
    type: "event-series",
    view_count: 36,
    is_featured: 0,
    created_at: "26 May 2024",
    post_thumbnail: "/images/cultivators/DSC01569.png",
    post_meta: {
      start_date: "2023-03-11T22:00:00.000000Z",
      end_date: "2023-03-12T21:59:59.000000Z",
      location: "World Wide",
      event_manager_id: "15",
    },
    tags: [],
  },
];

export const panelArr = [
  {
    id: 802,
    service_id: 17,
    title: "Investing in Deep Tech in the Middle East and Africa",
    title_slug: "panelI",
    post_summary: ` Investment in Deep Tech is gaining significant attention in recent years, particularly in the emerging markets of the the Middle East and Africa, during the second cultivators forum the topic was discussed at the Embassy of Egypt in lisbon, Portugal`,
    md_text: `The second panel convened at The Arab Republic of Egypt Embassy in Lisbon on November 13, 2023, in partnership with Instituto Pedro Nunes and the European Investment Bank, centered on exploring opportunities within the dynamic Middle East and Africa regions. These areas feature some of the globe's most rapidly expanding economies, presenting extensive markets eager for technology-driven solutions. The discussion underscored the presence of a youthful and tech-savvy workforce across the Middle East and Africa, offering a promising talent reservoir. Moreover, regional governments actively promote tech investment through various incentives and initiatives, cultivating an environment conducive to collaboration and innovation.`,
    type: "event",
    view_count: 37,
    is_featured: 0,
    created_at: "20 Sep 2023",
    post_thumbnail: "/images/cultivators/Cultivators-Lisbon-2023.png",
    panelists: [
      {
        id: 1,
        idea_name: "macroxeno",
        name: "Hesham Wahby",
        job_title: "CEO of Cultiv",
        profile_picture: "/images/cultivators/HeshamP2Grad.png",
      },
      {
        id: 2,
        idea_name: "Paulo-Santos",
        name: "Paulo Santos",
        job_title: "Business Incubator & Accelerator Director of IPN Incubadora",
        profile_picture: "/images/cultivators/PauloP2Grad.png",
      },
      {
        id: 3,
        idea_name: "Rui-Serapicos",
        name: "Rui Serapicos",
        job_title: "President of Portuguese Blockchain Alliance",
        profile_picture: "/images/cultivators/RuiP2Grad.png",
      },
      {
        id: 4,
        idea_name: "Ossi-Ritvos",
        name: "Ossi Ritvos",
        job_title: "Senior Business Advisor at Enter Espoa",
        profile_picture: "/images/cultivators/OssiP2Grad.png",
      },
      {
        id: 5,
        idea_name: "Besma-Kraiem",
        name: "Besma Kraiem",
        job_title: "Founding Partner of IBK International Business Consulting",
        profile_picture: "/images/cultivators/BesmaP2Grad.png",
      },
      {
        id: 6,
        name: "Jennifer Brady",
        idea_name: "Jennifer-Brady",
        job_title:
          "Senior Advisor, Foundry Fellowship at Legatum Center for Development & Entrepreneurship at MIT",
        profile_picture: "/images/cultivators/JenniferP2Grad.png",
      },
      {
        id: 7,
        name: "Mohamed Fahmy",
        idea_name: "Mohamed-Fahmy",
        job_title: "Managing Partner at Sequence Ventures",
        profile_picture: "/images/cultivators/MohamedP2Grad.png",
      },
    ],
    post_meta: {
      start_date: "2023-11-13T20:00:00.000000Z",
      end_date: "2023-11-13T22:00:00.000000Z",
      location: "The Arab Republic of Egypt Embassy in lisbon, Portugal",
      event_manager_id: "15",
    },
    post_cover: "/images/cultivators/panel2_cover.svg",
    tags: [],
    gallery: galleryImages2,
  },
  {
    id: 801,
    service_id: 16,
    title: "Impact investment in Africa and the MENA Region",
    title_slug: "panelII",
    post_summary: `
  Cairo 2022 was the first Cultivators-forum event to take place discussing the topic of "Impact investment in Africa and the MENA Region, the forum was held at The Ambassador of the Kingdom of Netherlands'residence.
  `,
    md_text: `
  Impact investment in Africa and the MENA Region was the focus of a panel discussion held on September 14, 2022, at The Ambassador of the Kingdom of Netherlands' residence in Cairo. The event highlighted the dual objectives of generating profit while creating positive social and environmental impacts. Addressing diverse socio-economic landscapes and limited access, key challenges were identified that complicate the sourcing and assessment of viable investments. These obstacles underscored the need for innovative approaches to effectively allocate capital and measure impact in the region.`,
    type: "event",
    view_count: 36,
    is_featured: 0,
    created_at: "14 Sep 2022",
    post_thumbnail: "/images/cultivators/Cultivators-Cairo-2022.png",
    post_meta: {
      start_date: "2022-09-14T20:00:00.000000Z",
      end_date: "2022-09-14T22:00:00.000000Z",
      location: "The Ambassador of the Kingdom of Netherlands' residence in Cairo",
      event_manager_id: "15",
    },
    post_cover: "/images/cultivators/panel1_cover.svg",
    tags: [],
    gallery: galleryImages,
    panelists: [
      {
        id: 1,
        idea_name: "ayman-ismail",
        name: "Ayman Ismail",
        job_title: "Abdul Latif Jameel Endowed Chair of Entrepreneurship",
        profile_picture: "/images/cultivators/AymanP1Grad.png",
      },
      {
        id: 2,
        idea_name: "ayman-shehata",
        name: "Ayman Shehata",
        job_title: "Managing Partner, ElRehla Moderator",
        profile_picture: "/images/cultivators/shehataP1Grad.png",
      },
      {
        id: 3,
        idea_name: "dalia-ibrahim",
        name: "Dalia Ibrahim",
        job_title: "CEO  of Nahdet Misr Publishing House and Founder of EdVentures",
        profile_picture: "/images/cultivators/DaliaP1Grad.png",
      },
      {
        id: 4,
        idea_name: "nivanne Mortagy",
        name: "Nivanne Mortagy",
        job_title: "Lead for North Africa, FIG, Upstream, IFC",
        profile_picture: "/images/cultivators/NivanneP1Grad.png",
      },
      {
        id: 5,
        idea_name: "Amr Abodraiaa",
        name: "Amr Abodraiaa",
        job_title: "CEO and Co-Founder of Rology",
        profile_picture: "/images/cultivators/AmrP1Grad.png",
      },
      {
        id: 6,
        name: "Ibrahim Sagna",
        idea_name: "ibrahim",
        job_title: "Global Head, Advisory & Capital Markets of Afreximbank",
        profile_picture: "/images/cultivators/IbrahimP1Grad.png",
      },
    ],
  },
];
