import { CultivContact, FooterRedirectLink } from "../schema";

export const contacts: CultivContact[] = [
  { contact: "CULTIVnet Lda, 132 Rua Dom Afonso Henriques, Cortes 4950-854, Portugal" },
  { contact: "CULTIV Business LLC, 124 Othman Ibn Afan Street, Heliopolis, Cairo 11361, Egypt" },
  { contact: "CULTIV LLC, 16192 Coastal Highway, Lewes, DE 19958, USA" },
];

export const footerLinks: FooterRedirectLink[] = [
  { title: "Home", pathname: "/" },
  { title: "About", pathname: "/about" },
  { title: "Cultivators", pathname: "/cultivators" },
  { title: "Bureau", pathname: "/bureau" },
  { title: "Careers", pathname: "/careers" },
];
