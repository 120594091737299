import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cultivatorsEventPanels } from "../assets/cultivatorsEventPanels";
import useHomeFeed from "../hooks/useHomeFeed";
import { RootState } from "../redux/configureStore";
import {
  getCurrentPage,
  nextPage,
  previousPage,
  reset,
  setMaxPage,
} from "../redux/features/pagePaginationSlice";
import { contentTypes } from "../schema";
import CardsGrid from "./CardsGrid";
import RightChevron from "./Icons/RightChevron";
import Loading from "./Loading";
import PodcastSeriesGrid from "./PodcastSeriesGrid";

export interface Props {
  pageId: string;
}

const HomeFilter = ({ pageId }: Props) => {
  const page = useSelector((state: RootState) => getCurrentPage(state.pagePagination, "home"));
  const [displayed, setDisplayed] = useState<string>("");

  const limit = useMemo((): string => {
    if (page === 1) {
      switch (displayed) {
        case contentTypes.All:
          return "4";

        case contentTypes.Events:
          return "5";

        default:
          return "6";
      }
    } else {
      return "6";
    }
  }, [displayed, page]);

  const [posts, isLoading, lastPage] = useHomeFeed(page, displayed, limit);

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToStart = () => {
    scrollRef.current?.scrollIntoView();
  };

  const dispatch = useDispatch();

  const handlePageChange = (direction: "next" | "previous") => {
    if (direction === "next" && page < lastPage) {
      dispatch(nextPage(pageId));
    } else if (direction === "previous" && page > 1) {
      dispatch(previousPage(pageId));
    }
    scrollToStart();
  };

  const handleDisplayChange = (type: string) => {
    if (displayed !== type) {
      setDisplayed(type);
      dispatch(reset(pageId));
    }
  };

  useEffect(() => {
    dispatch(setMaxPage({ id: "home", maxPage: lastPage }));
  }, [lastPage]);

  const leftArrowColor = page < 2 ? "#CCCCCCCC" : "black";
  const rightArrowColor = page === lastPage ? "#CCCCCCCC" : "black";

  return (
    <>
      <div
        ref={scrollRef}
        className="border-b-2 min-h-max border-cultiv-grey w-full grid lg:grid-cols-12 lg:pt-10 lg:pb-5 pb-2"
      >
        <div className="col-span-6 lg:col-span-7 lg:text-2xl text-sm flex justify-between">
          {Object.keys(contentTypes).map((type) => (
            <button
              className={`${
                displayed === contentTypes[type as keyof typeof contentTypes]
                  ? "font-medium"
                  : "text-cultiv-grey"
              } transition duration-500 hover:text-black`}
              key={type}
              onClick={() => handleDisplayChange(contentTypes[type as keyof typeof contentTypes])}
            >
              <h3>{type}</h3>
            </button>
          ))}
        </div>
      </div>

      <div className="min-h-[50vh] sm:min-h-[80vh] flex items-start justify-center">
        {displayed !== "podcasts" ? (
          isLoading ? (
            <Loading />
          ) : (
            <CardsGrid
              cards={
                page === 1 && (displayed === "" || displayed === "event")
                  ? [...cultivatorsEventPanels, ...posts]
                  : posts
              }
              podcast={page === 1 && displayed === "" && true}
            />
          )
        ) : (
          <PodcastSeriesGrid />
        )}
      </div>

      <div
        className={`${
          lastPage === 1 && "hidden"
        } lg:pt-2 flex justify-end self-center items-center`}
      >
        <button
          onClick={() => handlePageChange("previous")}
          disabled={page < 2}
          className={page < 2 ? "cursor-not-allowed opacity-50" : undefined}
        >
          <RightChevron color={leftArrowColor} className={`lg:w-8 w-6 rotate-180`} />
        </button>
        <div className="lg:px-6 px-3 lg:text-2xl text-base">{page}</div>
        <button
          onClick={() => handlePageChange("next")}
          disabled={page == lastPage}
          className={page === lastPage ? "cursor-not-allowed opacity-50" : undefined}
        >
          <RightChevron color={rightArrowColor} className={`lg:w-8 w-6`} />
        </button>
      </div>
    </>
  );
};

export default HomeFilter;
