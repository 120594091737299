import { CalendarIcon, ClockIcon, ListBulletIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { PodcastSeries } from "../schema";
import ShareBtn from "./Icons/ShareBtn";
import Image from "./Image";
import PodcastSummaryPlayer from "./PodcastSummaryPlayer";

export interface Props {
  series: PodcastSeries;
}

const PodcastSeriesCard = ({
  series: { description, image, title, slug, audioSummary },
}: Props) => {
  const [shareBtnHover, setShareBtnHover] = useState<boolean>(false);

  const shareHovered = () => {
    setShareBtnHover(true);
  };
  const shareNotHovered = () => {
    setShareBtnHover(false);
  };

  const onShare = async () => {
    await navigator.clipboard.writeText(`https://cultiv.llc/culture/podcast/${slug}`);
    toast.success("Link copied to clipboard", {
      className: "text-[#808080]",
    });
  };

  return (
    <div className="w-full h-full flex flex-col gap-6 border-cultiv-grey group border-2 col-span-4 md:col-span-1 xl:col-span-4">
      <Link to={`/culture/podcast/${slug}`}>
        <Image src={image} width={405} height={303} className="w-full aspect-4/3" />
      </Link>
      <div className="flex flex-col gap-8 ">
        <div className="flex flex-col gap-6 px-6">
          <div className="flex justify-between items-center text-cultiv-grey text-xs 2xl:text-sm w-full">
            <div className="flex gap-0.5 items-center justify-center">
              <CalendarIcon className="w-6 h-6" />
              <p className="text-xs h-full">Jun 2023</p>
            </div>
            <div className="flex gap-0.5 items-center justify-center">
              <ListBulletIcon className="w-6 h-6" />
              <p className="text-xs h-full">44 EP</p>
            </div>
            <div className="flex gap-0.5 items-center justify-center">
              <ClockIcon className="w-6 h-6" />
              <p className="text-xs h-full">20:53:00</p>
            </div>
          </div>
          <Link to={`/culture/podcast/${slug}`}>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1.5">
                <h3 className="font-semibold text-[22px] 2xl:text-4xl">{title}</h3>
                <p className="text-sm lg:text-base 2xl:text-lg leading-5 text-[#302D2D]">
                  {description}
                </p>
              </div>
            </div>
          </Link>
        </div>
        {audioSummary && (
          <div className="w-full px-6">
            <PodcastSummaryPlayer audioSummary={audioSummary} />
          </div>
        )}
        <div className="flex justify-end gap-6 w-full">
          <button onClick={onShare} onMouseEnter={shareHovered} onMouseLeave={shareNotHovered}>
            <ShareBtn textColor={shareBtnHover ? "text-black" : "text-[#808080]"} />
          </button>
          <div className="flex items-center justify-center col-span-1 bg-cultiv-red w-24 py-1">
            <h1 className="text-sm bg-cultiv-red text-black font-medium">PODCAST</h1>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default PodcastSeriesCard;
