import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import Image from "../components/Image";
import Loading from "../components/Loading";
import MarkDown from "../components/MarkDown";
import WaveformAudioPlayer from "../components/WaveformAudioPlayer";
import usePageReset from "../hooks/usePageReset";
import usePodcastEpisode from "../hooks/usePodcastEpisode";

const PodcastEpisode = () => {
  const { podcastEpisodeID } = useParams();
  const { episode, currentEpisode, isLoading } = usePodcastEpisode(podcastEpisodeID as string);
  const pageReset = usePageReset();
  const context = useRef<AudioContext>();
  const fileData = episode?.filename.split("-");
  const episodeNumber = Number(fileData?.[0])?.toString();
  const trimTitle = fileData?.[1].replace(/[() \d+]/g, "");
  const episodeName = trimTitle?.charAt(0).toUpperCase() + trimTitle?.slice(1);

  useEffect(() => {
    context.current = new AudioContext();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="radio el afkar podcast" />
        <meta name="description" content={`this is radio el afkar podcast`} />
        <meta name="robots" content="index, follow" />
        <title>{`${`EP#${episodeNumber}: ${episodeName}` || "Cultiv"}`}</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <div className="grid grid-cols-4 sm:grid-cols-12 gap-8 w-full  px-6 sm:px-12 xl:px-20 py-15">
          <div className="flex flex-col gap-4 sm:col-start-2 sm:col-end-8 lg:col-end-9">
            <div className="flex flex-col gap-2 ">
              <p className="text-2xl text-[#1A1A1A]">
                EP#{episodeNumber}: {episodeName}
              </p>
              <p className="text-[#808080]">Posted on 31-12-2015 by Hesham Wahby</p>
            </div>
            <div className=" flex flex-col justify-between 2xl:justify-evenly flex-1">
              <div className="flex flex-col justify-center gap-12 ">
                <div className="flex items-center border-1  col-span-4 sm:col-span-8  xl:col-span-12 row-start-2 gap-2 sm:gap-4 pr-2 sm:pr-4 border-black">
                  <Image
                    width={80}
                    height={80}
                    src="/images/CULTIV.svg"
                    className="size-12 sm:size-16 xl:size-20 bg-black p-1"
                  />
                  <WaveformAudioPlayer
                    className="gap-2 w-full min-h-full sm:size-9 flex-1"
                    episode={episode.url}
                    waveHeight={36}
                    shareEpisode={true}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2 sm:gap-11">
                    <div className="flex gap-1 sm:gap-2">
                      <p className="font-light  text-[#302D2D]">Host:</p>
                      <p className="text-cultiv-blue">Hesham Wahby</p>
                    </div>
                  </div>
                  <div className="flex gap-11">
                    <div className="flex gap-2">
                      <p className="font-light text-[#302D2D]">Tags:</p>
                      {currentEpisode[0]?.categories.map((category, index) => {
                        return (
                          <p key={index} className="font-extralight sm:text-lg text-cultiv-blue">
                            {category}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Image
            width={272}
            height={188}
            src="/images/radioaf.jpg"
            className="w-full h-full object-cover aspect-square row-start-1 sm:row-auto col-span-4  xl:col-end-12  lg:col-span-3"
          />
          <div className="font-extralight leading-6 3xl:text-xl col-span-full sm:col-start-2 sm:col-end-12 text-[#302D2D]">
            <MarkDown articleBody={currentEpisode[0].content} arabic={true} />
          </div>
        </div>
      )}
    </>
  );
};

export default PodcastEpisode;
