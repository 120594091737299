import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import RightChevron from "../components/Icons/RightChevron";
import PodcastEpisodeEntry from "../components/PodcastEpisodeEntry";
import WaveformAudioPlayer from "../components/WaveformAudioPlayer";
import usePageReset from "../hooks/usePageReset";
import usePodcast from "../hooks/usePodcast";

const pageSize = 4;

const PodcastSeries = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { podcastSeriesID } = useParams();
  const context = useRef<AudioContext>();
  const { seriesEpisodes } = usePodcast(podcastSeriesID as string);
  const totalPages = Math.ceil(seriesEpisodes.length / pageSize);

  usePageReset();

  const currentArray = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return seriesEpisodes.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, seriesEpisodes]);

  useEffect(() => {
    context.current = new AudioContext();
  }, [currentPage]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
      context.current?.resume();
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
      context.current?.resume();
    }
  };

  const leftArrowColor = currentPage < 2 ? "#CCCCCCCC" : "black";
  const rightArrowColor = currentPage === totalPages ? "#CCCCCCCC" : "black";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="radio el afkar podcast" />
        <meta name="description" content={`this is radio el afkar podcast`} />
        <meta name="robots" content="index, follow" />
        <title>Cultiv | Radio el afkar podcast</title>
      </Helmet>
      <div className="w-full min-h-screen flex flex-col gap-10">
        <section className=" py-10 px-6 sm:px-10 xl:px-20 bg-black">
          <div className="grid grid-cols-4 sm:grid-cols-12 gap-4 sm:gap-8 items-center justify-between">
            <h1 className="text-xl md:text-3xl xl:text-4xl 2xl:text-5xl text-nowrap col-span-4 sm:col-span-12 text-center text-white w-full ">
              Radio el-afkar in a nutshell
            </h1>
            <WaveformAudioPlayer
              episode="https://posts.bb.cultiv.llc/storage/podcast/radio-elafkar/001-intro.mp3"
              className="z-20 gap-2 p-3 col-start-1 sm:col-start-2 col-end-5 sm:col-end-12 row-start-2"
              fillIcons="text-[#b9b6b7]"
              hoverFillIcons="text-white"
              waveColor="#b9b6b7"
              progressColor="#fff"
              timerTextColor="text-white"
            />
          </div>
        </section>

        <section className="grid grid-cols-4 sm:grid-cols-12 gap-4 sm:gap-8 flex-1 w-full px-6 sm:px-10 xl:px-20">
          <div className="flex flex-col gap-10 col-span-4 sm:col-start-2 sm:col-end-12 sm:col-span-10">
            {currentArray.map(({ url, filename }, index) => {
              const fileData = filename.split("-");
              const episodeNumber = Number(fileData[0]);
              const trimTitle = fileData[1].replace(/[() \d+]/g, "");
              const key = trimTitle + Math.ceil(Math.random() * 10).toString();
              const episodeTitle = trimTitle.charAt(0).toUpperCase() + trimTitle.slice(1);
              return (
                <PodcastEpisodeEntry
                  key={key || index}
                  episode={url}
                  episodeNumber={episodeNumber}
                  episodeName={episodeTitle}
                  episodeBasename={filename}
                />
              );
            })}
          </div>
        </section>
        <section className="w-full flex items-center justify-center">
          <button
            onClick={prevPage}
            disabled={currentPage < 2}
            className={currentPage < 2 ? "cursor-not-allowed opacity-50" : undefined}
          >
            <RightChevron color={leftArrowColor} className="lg:size-8 size-6 rotate-180" />
          </button>
          <p className="lg:px-6 px-3 lg:text-2xl text-sm">{currentPage}</p>
          <button
            onClick={nextPage}
            disabled={currentPage == totalPages}
            className={currentPage == totalPages ? "cursor-not-allowed opacity-50" : undefined}
          >
            <RightChevron color={rightArrowColor} className="lg:size-8 size-6" />
          </button>
        </section>
      </div>
    </>
  );
};

export default PodcastSeries;
