import usePodcastSeries from "../hooks/usePodcastSeries";
import Loading from "./Loading";
import PodcastSeriesCard from "./PodcastSeriesCard";

const PodcastSeriesGrid = () => {
  const [series, isLoading] = usePodcastSeries();

  return isLoading ? (
    <Loading />
  ) : (
    <div className="flex gap-10 flex-col">
      <div className="grid xl:grid-cols-12 md:grid-cols-2 grid-cols-4 gap-4 lg:gap-8 w-full place-items-start">
        {series.map((series) => {
          return <PodcastSeriesCard key={series.id} series={series} />;
        })}
      </div>
    </div>
  );
};

export default PodcastSeriesGrid;
