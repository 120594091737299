import { Transition } from "@headlessui/react";
import { useState } from "react";
import WaveformAudioPlayer from "./WaveformAudioPlayer";
import { PlayIcon } from "@heroicons/react/20/solid";

export interface Props {
  audioSummary: string;
}

const PodcastSummaryPlayer = ({ audioSummary }: Props) => {
  const [pressed, setPressed] = useState<boolean>(false);

  return (
    <div
      className={` h-14 ${
        pressed ? "w-full" : "w-fit"
      } border-2 flex flex-col gap-2 relative border-cultiv-grey group-hover:border-black`}
    >
      <Transition
        show={!pressed}
        enterFrom="opacity-100"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`h-full w-full`}
      >
        <div
          className="px-2.5 py-0.5 max-w-fit h-full cursor-pointer text-cultiv-grey group-hover:text-black"
          onClick={() => {
            setPressed(!pressed);
          }}
        >
          <div className="h-full flex gap-2.5 items-center justify-center text-cultiv-grey group-hover:text-black ">
            <div className="p-2 flex items-center justify-center rounded-full border-2 border-cultiv-grey group-hover:border-black">
              <PlayIcon className="w-4 h-4 2xl:w-6 2xl:h-6 " />
            </div>
            <p className="text-sm lg:text-base">Play summary</p>
          </div>
        </div>
      </Transition>

      <Transition
        show={pressed}
        enter="transition-width ease-in-out duration-300"
        enterFrom="w-0"
        enterTo="w-full"
        leave="transition-width ease-in-out duration-200"
        leaveFrom="w-full"
        leaveTo="w-0"
        className={`absolute h-14 flex items-center top-0 left-0`}
      >
        <WaveformAudioPlayer
          className="gap-1.5 items-center px-2.5 w-full"
          episode={audioSummary}
        />
      </Transition>
    </div>
  );
};

export default PodcastSummaryPlayer;
