import { Link } from "react-router-dom";
import { rssFeedItems } from "../assets/rssFeedItems";
import Image from "./Image";
import MarkDown from "./MarkDown";
import WaveformAudioPlayer from "./WaveformAudioPlayer";

export interface Props {
  /**
   * Episode audio URL
   */
  episode: string;

  episodeBasename: string;

  episodeNumber: number;

  episodeName: string;
}

const PodcastEpisodeEntry = ({ episode, episodeNumber, episodeName, episodeBasename }: Props) => {
  const currentEpisode = rssFeedItems.filter(({ link }) =>
    link.includes(episodeBasename.replace(/[() \d+]/g, ""))
  );
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <Link
          to={`/culture/podcast/episode/${episodeBasename}`}
          className="text-2xl text-[#1A1A1A] hover:text-cultiv-yellow max-w-fit"
        >
          EP#{episodeNumber}: {episodeName}
        </Link>
        <p className="text-[#808080]">Posted on 31-12-2015 by Hesham Wahby</p>
      </div>
      <div className="flex items-center border-1 gap-1 sm:gap-4 pr-2 sm:pr-4 border-black">
        <Image
          width={80}
          height={80}
          src="/images/CULTIV.svg"
          className="size-9 sm:size-20 bg-black p-0.5 sm:p-1"
        />
        <WaveformAudioPlayer
          className="gap-2 flex-1 sm:size-9 min-h-full"
          episode={episode}
          shareEpisode={true}
          waveHeight={36}
        />
      </div>
      <div className="grid grid-cols-4 sm:grid-cols-10 gap-4 sm:gap-8">
        <div className="col-span-4 sm:col-span-6 xl:col-span-7 flex flex-col gap-10">
          <div className="font-extralight leading-6 text-sm sm:text-base 3xl:text-xl relative text-[#302D2D] ">
            <MarkDown articleBody={currentEpisode[0]?.summary} arabic={true} />
          </div>
          <div className="flex flex-col flex-1 gap-3 ">
            <div className="flex gap-11">
              <div className="flex gap-2 items-center">
                <p className="font-light text-[#302D2D]">Host:</p>
                <p className="sm:text-lg text-cultiv-blue">Hesham Wahby</p>
              </div>
            </div>
            <div className="flex gap-11">
              <div className="flex gap-2">
                <p className="font-light text-[#302D2D]">Tags:</p>

                {currentEpisode[0]?.categories.map((category, index) => {
                  return (
                    <p key={index} className="sm:text-lg text-cultiv-blue">
                      {category}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Image
          width={298}
          height={298}
          src="/images/radioaf.jpg"
          className=" col-span-4 xl:col-start-8 xl:col-end-11 w-full"
        />
      </div>
    </div>
  );
};

export default PodcastEpisodeEntry;
