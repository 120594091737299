import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import Image from "../components/Image";
import Loading from "../components/Loading";
import MarkDown from "../components/MarkDown";
import useArticle from "../hooks/useArticle";
import usePageReset from "../hooks/usePageReset";

export default function Article() {
  const { articleID } = useParams();
  const [post, isLoading] = useArticle(articleID as string);
  const pageReset = usePageReset();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content={`${post?.title} blog post article cultiv`} />
        <meta
          name="description"
          content={`This is an article about ${post?.title} written by ${post?.post_meta?.author}`}
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:url" content="https://staging.cultiv.llc" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          itemProp="image"
          content={post ? post.post_thumbnail : "https://staging.cultiv.llc/images/metaimg.jpg"}
        />
        <link
          rel="image_src"
          itemProp="image"
          href={post ? post.post_thumbnail : "https://staging.cultiv.llc/images/metaimg.jpg"}
        />
        <meta
          property="og:description"
          content={post ? post.post_summary : "The staging platform of cultiv.llc website"}
        />
        <meta property="og:site_name" content="Cultiv LLC" />
        <meta property="og:title" content={post ? post.title : "Staging Cultiv LLC"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://staging.cultiv.llc" />
        <meta property="twitter:title" content="Staging Cultiv" />
        <meta property="twitter:description" content="This the cultiv staging website" />
        <meta property="twitter:image" content="https://staging.cultiv.llc/images/metaimg.jpg" />
        <link rel="canonical" href="https://staging.cultiv.llc" />

        <title>Cultiv | {`${post ? post.title : "Article"}`}</title>
      </Helmet>

      {isLoading ? (
        <div className="flex justify-center items-center h-0 min-h-screen">
          <Loading />
        </div>
      ) : (
        <div className="w-full flex flex-col justify-center items-center gap-3 lg:gap-5 lg:px-20 px-6 pt-8">
          <div className="flex flex-col gap-3 w-full justify-center items-center pb-3 lg:pb-8 border-b-2 border-gray-400 text-center">
            <h1 className="text-xl md:text-3xl lg:text-4xl font-bold">{post?.title}</h1>
            <h3 className="text-xs md:text-base lg:text-xl text-gray-500">
              {post?.created_at}
              {post.post_meta.author && `, by ${post?.post_meta?.author}`}
            </h3>
          </div>
          <div className="lg:px-20 flex flex-col justify-center">
            <Image
              src={post?.post_cover}
              width={1120}
              height={630}
              className="object-cover self-center max-h-[220px] lg:max-h-[630px] mb-3 lg:mb-10"
              alt={post?.title}
            />
            <MarkDown articleBody={post?.md_text} />
          </div>
        </div>
      )}
    </>
  );
}
